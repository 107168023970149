	<header id="header" class="alt">
		<span class="logo"><img src="images/logo.svg" alt="" /></span>
		<h1>Test</h1>
		<p>Test.</p>
	</header>

	<nav id="nav">
		<ul>
			<li><a href="#intro" class="active">Introduction</a></li>
			<li><a href="#first">First Section</a></li>
			<li><a href="#second">Second Section</a></li>
			<li><a href="#cta">Get Started</a></li>
		</ul>
	</nav>
