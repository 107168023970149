	<footer id="footer">
		<section>
			<h2>Test</h2>
			<p>Test.</p>
			<ul class="actions">
				<li><a href="generic.html" class="button">Learn More</a></li>
			</ul>
		</section>
		<section>
			<h2>Test</h2>
			<ul class="alt">
				<li>Tets</li>
			</ul>
			<ul class="icons">
				<li><a href="#" class="icon brands fa-twitter alt"><span class="label">Twitter</span></a></li>
				<li><a href="#" class="icon brands fa-facebook-f alt"><span class="label">Facebook</span></a></li>
				<li><a href="#" class="icon brands fa-instagram alt"><span class="label">Instagram</span></a></li>
				<li><a href="#" class="icon brands fa-github alt"><span class="label">GitHub</span></a></li>
				<li><a href="#" class="icon brands fa-dribbble alt"><span class="label">Dribbble</span></a></li>
			</ul>
		</section>
		<p class="copyright">&copy; Ssum.uk: <a href="https://ssumbot.com">Ssumbot.com</a>.</p>
	</footer>
